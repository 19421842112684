import React from 'react'
import { useTranslation } from 'react-i18next'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { Link } from 'gatsby'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const Footer = () => {
  const { t } = useTranslation()
  return (
    <>
      <section id="free-training">
        <Container>
          <Row className="align-items-center">
            <Col md="8">
              <h3
                className="mb-4"
                dangerouslySetInnerHTML={{
                  __html: t('pages.home.coaches.freeTraining.title'),
                }}
              ></h3>
              <p>{t('pages.home.coaches.freeTraining.description')}</p>
            </Col>
            <Col md="4">
              <div className="d-sm-flex justify-content-end">
                <Link to="/contact" className="btn btn-main btn-inverse">
                  <span>{t('buttons.apply')}</span>
                  <FontAwesomeIcon
                    icon="chevron-right"
                    className="icon"
                    size="lg"
                  />
                </Link>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <footer id="footer">
        <Container>
          <Row>
            <Col md="6" className="contact-info-wrapper">
              <h3>{t('footer.contact')}</h3>
              <hr />
              <ul>
                <li>
                  <FontAwesomeIcon
                    icon="map-marker-alt"
                    className="icon"
                    size="sm"
                  />
                  {t('address')}
                </li>
                <li>
                  <FontAwesomeIcon icon="phone" className="icon" size="sm" />
                  +36 (70) 679-0926
                </li>
                <li>
                  <FontAwesomeIcon icon="envelope" className="icon" size="sm" />
                  zrteampecs@gmail.com
                </li>
                <li>
                  <FontAwesomeIcon
                    icon={['fab', 'facebook']}
                    className="icon"
                    size="sm"
                  />
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://facebook.com/zteambjjpecs"
                  >
                    facebook.com/zrteambjjpecs
                  </a>
                </li>
                <li>
                  <FontAwesomeIcon
                    icon={['fab', 'instagram']}
                    className="icon"
                    size="sm"
                  />
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://instagram.com/zrteampecs"
                  >
                    instagram.com/zrteampecs
                  </a>
                </li>
              </ul>
            </Col>
            <Col md="12">
              <div className="copyright-wrapper">
                <hr className="copyright-divider" />
                <small className="copyright">
                  © {t('footer.copyright')} | {new Date().getFullYear()} - ZR
                  Team Pécs
                </small>
              </div>
            </Col>
          </Row>
        </Container>
      </footer>
    </>
  )
}

export default Footer
