import React from 'react'
import { library, config } from '@fortawesome/fontawesome-svg-core'
import { fab } from '@fortawesome/free-brands-svg-icons'
import {
  faPhone,
  faEnvelope,
  faMapMarkerAlt,
  faChevronRight,
  faUser,
} from '@fortawesome/free-solid-svg-icons'

import '../i18n/config'
import '../assets/styles/main.scss'

import Header from './header'
import Footer from './footer'
import Seo from '../components/seo'

config.autoAddCss = false
library.add(fab, faPhone, faEnvelope, faMapMarkerAlt, faChevronRight, faUser)

const Layout = ({
  children,
  title = false,
  description = false,
  image = false,
  path = false,
}) => (
  <>
    <Seo title={title} description={description} image={image} path={path} />
    <Header />
    <main>{children}</main>
    <Footer />
  </>
)

export default Layout
