import React from 'react'
import { Link } from 'gatsby'
import { useTranslation } from 'react-i18next'

import Navbar from 'react-bootstrap/Navbar'
import Nav from 'react-bootstrap/Nav'

import LanguageMenu from './LanguageMenu'

const Header = () => {
  const { t } = useTranslation()
  return (
    <Navbar id="main-nav" collapseOnSelect fixed="top" expand="lg">
      <Navbar.Brand>
        <Link to="/" className="logo">
          ZR Team <strong>Pécs</strong>
        </Link>
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="main-navbar" />
      <Navbar.Collapse id="main-navbar">
        <Nav className="ml-auto">
          <Link to="/" className="nav-link">
            {t('auth.navigation.home')}
          </Link>
          <Link to="/history" className="nav-link">
            Jiu Jitsu
          </Link>
          <Link to="/training-schedule" className="nav-link">
            {t('auth.navigation.training')}
          </Link>
          <Link to="/contact" className="nav-link">
            {t('auth.navigation.contact')}
          </Link>
        </Nav>
      </Navbar.Collapse>
      <LanguageMenu />
    </Navbar>
  )
}

export default Header
