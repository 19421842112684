import React from 'react'
import { useTranslation } from 'react-i18next'

const LanguageMenu = () => {
  const { i18n } = useTranslation()

  const languages = ['hu', 'en']
  const changeLanguage = (lng) => i18n.changeLanguage(lng)
  const isCurrentLanguage = (lang) => i18n.language !== lang

  return (
    <div className="language-menu">
      {languages.map(
        (language, index) =>
          isCurrentLanguage(language) && (
            <button
              className="language-menu-item"
              key={index}
              onClick={() => changeLanguage(language)}
            >
              {language.toUpperCase()}
            </button>
          ),
      )}
    </div>
  )
}

export default LanguageMenu
