import * as React from 'react'
import Helmet from 'react-helmet'
import { useStaticQuery, graphql } from 'gatsby'

function Seo(props) {
  const data = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            image
            siteUrl
          }
        }
      }
    `,
  )

  const defaults = data?.site?.siteMetadata

  const title = props.title || defaults.title
  const description = props.description || defaults.description
  const image = new URL(props.image || defaults.image, defaults.siteUrl)
  const url = new URL(props.path || '/', defaults.siteUrl)

  return (
    <Helmet
      htmlAttributes={{
        lang: 'hu',
      }}
      titleTemplate={title ? `%s | ZR Team Pécs` : null}
    >
      <title>{title}</title>
      <meta
        name="google-site-verification"
        content="-TZlUk43js5t8U-Vt6hLs7zoSLL6DScHQOFP5pRp2Z4"
      />
      <meta name="description" content={description} />
      <link rel="canonical" href={url} />
      {image && <meta name="image" content={image} />}

      <meta property="og:url" content={url} />
      <meta property="og:type" content="website" />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      {image && <meta name="og:image" content={image} />}

      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      {image && <meta name="twitter:image" content={image} />}

      <link rel="preconnect" href="https://fonts.googleapis.com" />
      <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
      <link
        href="https://fonts.googleapis.com/css2?family=Open+Sans&family=Raleway:wght@400;700;900&display=swap"
        rel="stylesheet"
      ></link>
    </Helmet>
  )
}

export default Seo
